import React, { useState } from "react";

import { useForm } from "./shared/hooks/form-hook";
import Footer from "./Footer"

import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "./shared/util/validators";
import Input from "./shared/UiElements/Input";
import axios from "axios";

import "./Contact.css";

function Contact() {
  const [sent, setSent] = useState(false);

  let [formState, inputHandler, setFormData] = useForm({
    name: { value: "", isValid: false },
    email: { value: "", isValid: false },
    message: { value: "", isValid: false },
  }, false);

function setForm(){
  setFormData({
    name: { value: "", isValid: false },
    email: { value: "", isValid: false },
    message: { value: "", isValid: false },
  }, false);
}
  async function handleSubmit(e) {
    try {
      axios.post(`${process.env.REACT_APP_MAIL_URL}/send`, {
        ...formState,
        name: formState.inputs.name.value,
        email: formState.inputs.email.value,
        message: formState.inputs.message.value,
      });
      setSent(true)
      setForm()
    } catch (err) {
      console.log(err);
    }
    e.preventDefault();
  }


  return (
    <React.Fragment>
   
      <section id="contact">
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 100 102"
          height="75"
          width="100vw"
          xmlns="http://www.w3.org/2000/svg"
          className="svgcolo-light"
        >
          <path d="M0 0 L50 100 L100 0 Z" stroke="white"></path>
        </svg>
        <div className="container flex">
          <div className="header">
            <h2> Contact </h2>
          </div>
    <p className='email-prompt'>If you would like to contact me or work with me, please use the form below!</p>
          <form id="contact-form" className="contact-form" onSubmit={handleSubmit}>
            <Input
              id="name"
              type="text"
              placeholder="Name"
              element="input"
              validators={[VALIDATOR_REQUIRE]}
              errorText="Please enter a Name"
              value={formState.inputs.name.value}
              onInput={inputHandler}
            />

            <Input
              id="email"
              type="text"
              placeholder="Email"
              element="input"
              validators={[VALIDATOR_REQUIRE, VALIDATOR_EMAIL]}
              errorText="Please enter a valid email"
              onInput={inputHandler}
            />

            <Input
              id="message"
              element="textarea"
              className="form-control"
              placeholder="Message"
              rows="5"
              validators={[VALIDATOR_REQUIRE]}
              errorText="Please enter a message"
              onInput={inputHandler}
            />
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!formState.isValid}
              // onClick={handleSubmit}
            >
              Submit!
            </button>
            {sent && <p className='email-sent'>Email Sent!</p>}
          </form>
        </div>
        
      </section>
      <Footer />
    </React.Fragment>
  );
}

export default Contact;
