import React from "react";

import ExerApp from "../images/ExerApp-Thumbnail.png"
import PerryLogan from "../images/PerryLogan-Thumbnail.png"
import GitHub from"../images/github-Thumbnail.png"
import Card from "./shared/UiElements/Card";

import "./Portfolio.css";
function Portfolio() {
  return (
    <section id="portfolio">
      <div className="header">
        <h2>PORTFOLIO</h2>
      </div>
      <div className="container flex">
        <div className="row ">
          <Card
          
            src={ExerApp}
            alt={"ExerApp Thumbnail"}
            title={"MERN based Apps"}
            text={"ExerApp is a web app built with React, with Node.js and express.js, and MongoDB"}
            href={"https://exerapp1.web.app"}
            linkText={"ExerApp"}
          />
          <Card

            src={GitHub}
            alt={"GitHub Thumbnail"}
            title={"GitHub Projects"}
            text={"Check out behind the scenes and other projects on GitHub"}
            href={"https://github.com/PecanPI"}
            linkText={"GitHub"}
          />
          <Card

            src={PerryLogan}
            alt={"Freelance"}
            title={"Freelance"}
            text={"Have an awesome idea for a website? I do freelance! Contact me below"}
            href={"#contact"}
            linkText={"Contact Me!"}
          />
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
