import React, { useEffect } from "react";

import "./Home.css";

function Home() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "../scripts/starDots.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <section id="home">
      {" "}
      <div className="canvas" id="pt"></div>
      <div className="flex">
        <h1>
          Hello, I'm <span className="highlight">Matthew Logan</span>A Full
          Stack Developer
        </h1>
        <button
          className=" view-work-btn"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "#about";
          }}
        >
          {" "}
          View my Work!
        </button>
      </div>
    </section>
  );
}

export default Home;
