import React from "react";

function Card(props) {
  return (
    
      <div className="card col-lg slide-in-bottom">
        <img className="card-img-top" src={props.src} alt={props.alt}></img>
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.text}</p>
          <a href={props.href} className="btn ">
            {props.linkText}
          </a>
        </div>
      </div>
    
  );
}

export default Card;
