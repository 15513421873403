import React from 'react'

import "./Footer.css"
let date =  new Date()

function Footer() {
    return (
        <footer>
        <div className='row justify-content-md-center'>
            <div className='col footer-icon'>
                <a href='https://linkedin.com/in/matt-r-logan/'><i className="fab fa-linkedin fa-3x"></i></a>
            </div>
            <div className='col footer-icon'>
                <a href='https://github.com/PecanPI'><i className="fab fa-github fa-3x"></i></a>
            </div>
        </div>
            <div className='container'>
                <span className='text-muted'> Matthew Logan ©{date.getFullYear()}</span>
            </div>
        </footer>
    )
}

export default Footer
