import React from "react";

import "./Graph.css"

function Graph(props) {
  return (
    <div className="bar-div">
      <span className="label">{props.label}</span>
      <div
        className="bar"
        data-percentage={props.data}
        style={{ width: `${props.data - 22}%`  }}
      >
      </div>
    </div>
  );
}

export default Graph;
