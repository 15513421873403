import React from "react";


import Graph from "./Graph";

import "./About.css";

function About() {
  const data = [
    {
      name: "HTML",
      value: 90,
    },
    {
      name: "CSS",
      value: 90,
    },
    {
      name: "Python",
      value: 85,
    },
    {
      name: "Javascript",
      value: 80,
    },
    {
      name: "React",
      value: 80,
    },
    {
      name: "Java",
      value: 80,
    },
    {
      name: "NodeJs",
      value: 75,
    },
    {
      name: "MongoDB",
      value: 75,
    },
    {
      name: "ExpressJs",
      value: 70,
    },
  ];

  return (
    <section id="about">
      <div className="header">
        <h2>ABOUT</h2>
      </div>
      <div className="container flex">
        <img
          className="headshot"
          src={
            "https://media-exp1.licdn.com/dms/image/C4E03AQEPwpie4jLG_A/profile-displayphoto-shrink_800_800/0/1583890969527?e=1630540800&v=beta&t=8qVgcA6wc3A3BJ3coPIm39dIHyU1TmBCpTcSgRab8gk"
          }
          alt="Matthew headshot"
        />
        <div className="row">
          <div className="col-lg">
            <div className="about-blurb">
              <p>
                 I am Matthew Logan a full stack developer from Calgary, Alberta.
                 </p><p> 
                 I have a passion for taking problems and turning the solutions into code.
                 I am a lifelong learner and love to learn about new technologies and frameworks that help me build spectacular programs.
                 
              </p>
              <p>When not coding you can find me walking my dog Fig, playing Dungeons and Dragons, or training for my next half marathon.</p>
              <p>
                {" "}
                If you would like to work with or talk to me,{" "}
                <a href="#contact">contact me below!</a>
              </p>
            </div>
          </div>
          <div className="col-lg bar-col">
            <div className="bar-graph bar-graph-horizontal ">
              {data.map((data, index) => {
                return (
                  <Graph key={index} label={data.name} data={data.value} />
                );
              })}
            </div>
            {/* <h3 className='chart-header'>Skills</h3>
          <VictoryChart domainPadding={20} padding={{left: 75, bottom:50, right:15}} height={250} width={250} fontSize={"1rem"}>
            <VictoryBar
              horizontal={true}
              barRatio={.5}
              data={data}
              style={{data:{fill :'#00adb5'}}}
              x="name"
              y="value"
              fontSize={10}
              animate={{ duration: 2000, onLoad: { duration: 1000 } }}
              styles={{labels: {fontSize: "1rem"}}}
            />

          </VictoryChart> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
